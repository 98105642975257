.coin-container {
	display: flex;
	justify-content: center;
}

.coin-row {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	height: 80px;
	border-bottom: 1px solid #d7d7d7;
	width: 900px;
}

.coin {
	display: flex;
	align-items: center;
	padding-right: 24px;
	min-width: 300px;
}

.coin h1 {
	font-size: 16px;
	width: 150px;
}

.coin img {
	height: 30px;
	width: 30px;
	margin-right: 10px;
}

.coin-symbol {
	text-transform: uppercase;
}

.coin-data {
	display: flex;
	text-align: center;
	justify-content: space-between;
	width: 100%;
}

.coin-price {
	width: 110px;
}

/* Initial Style */
/* .coin-volume {
	width: 155px;
}

.coin-marketcap {
	width: 230px;
}

.coin-precent {
	width: 100%;
} */

.coin-volume {
	width: 200px;
}

.coin-marketcap {
	width: 240px;
}

.coin-precent {
	width: 80px;
}

.red {
	color: #f00606;
}

.green {
	color: #11d811;
}
